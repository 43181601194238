<template>
    <div class="order">
        <div class="con_top">
            <div class="item i_c_1">
                <div class="item_l">
                    <div class="label">钻石余额</div>
                    <div class="value">{{ userInfo.diamond || 0 }}
                        <img src="~@/assets/images/person/accountManage/zuan.png" alt="">
                    </div>
                </div>
                <div class="item_r" @click="investBtn(4)">
                    <div class="btn btn1">去充值 ></div>
                </div>
            </div>
            <div class="item i_c_2">
                <div class="item_l">
                    <div class="label">视频时长余额</div>
                    <div class="value">
                        <div v-if="$utils.formatSeconds(userInfo.video_duration, 3).h">
                            {{ $utils.formatSeconds(userInfo.video_duration, 3).h }}<span>小时</span></div>
                        <div v-if="$utils.formatSeconds(userInfo.video_duration, 3).m">
                            {{ $utils.formatSeconds(userInfo.video_duration, 3).m }}<span>分钟</span></div>
                        <div>{{ $utils.formatSeconds(userInfo.video_duration, 3).s }}<span>秒</span></div>
                    </div>
                </div>
                <div class="item_r" @click="investBtn(0)">
                    <div class="btn btn2">去充值 ></div>
                </div>
            </div>
            <div class="item i_c_3">
                <div class="item_l">
                    <div class="label">绘画次数余额</div>
                    <div class="value">{{ userInfo.drawing || 0 }}<span>次</span></div>
                </div>
                <div class="item_r" @click="investBtn(2)">
                    <div class="btn btn3">去充值 ></div>
                </div>
            </div>
            <div class="item i_c_4">
                <div class="item_l">
                    <div class="label">音频时长余额</div>
                    <div class="value">
                        <div v-if="$utils.formatSeconds(userInfo.audio_duration, 3).h">
                            {{ $utils.formatSeconds(userInfo.audio_duration, 3).h }}<span>小时</span></div>
                        <div v-if="$utils.formatSeconds(userInfo.audio_duration, 3).m">
                            {{ $utils.formatSeconds(userInfo.audio_duration, 3).m }}<span>分钟</span></div>
                        <div>{{ $utils.formatSeconds(userInfo.audio_duration, 3).s }}<span>秒</span></div>
                    </div>
                </div>
                <div class="item_r" @click="investBtn(1)">
                    <div class="btn btn4">去充值 ></div>
                </div>
            </div>
        </div>
        <div class="con_bottom sec_wrap">
            <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="充值" name="oTab1" v-if="$has('514')"></el-tab-pane>
                <el-tab-pane label="消耗" name="oTab2" v-if="$has('515')"></el-tab-pane>
                <el-tab-pane label="兑换码" name="oTab3" v-if="$has('515')"></el-tab-pane>
            </el-tabs>
            <div class="content1 p20" v-if="activeName == 'oTab1' && $has('514')">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small' v-if="$has('516')">
                    <el-form-item label="充值来源" props="order_source">
                        <el-select v-model="params.order_source" placeholder="请选择充值来源" @change="search">
                            <el-option v-for="item in order_source1" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下单日期">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="search" :clearable="true">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="table1" :data="list" v-loading="loading" @sort-change="onSortChange" style="width: 100%"
                        header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_source_text" label="充值来源">
                        </el-table-column>
                        <el-table-column prop="order_num" label="订单编号">
                        </el-table-column>
                        <el-table-column prop="order_name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="" label="充值额度">
                          <template slot-scope="scope">
                            <div class="details_text" v-if="scope.row.details && scope.row.details.length">
                              <div class="text" v-for="(item,index) in scope.row.details" :key="index">
                                {{item.name}}-<span>{{item.profie}}{{item.unit}}</span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="order_status_text" label="订单状态">
                        </el-table-column>
                        <el-table-column prop="create_time" label="下单时间" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="pay_time" label="付款时间" sortable="custom">
                          <template slot-scope="scope">
                            <span>{{scope.row.pay_time || '--'}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="" label="充值帐户">
                            <template slot-scope="scope">
                                <div v-if="scope.row.member">{{ scope.row.member.username }}</div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="balance" label="当前余额 (钻)">
                        </el-table-column> -->
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                            @currentChange='currentChangeBtn'></paging-page>
                    </div>
                </div>
            </div>
            <div class="content2 p20" v-if="activeName == 'oTab2' && $has('515')">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small' v-if="$has('516')">
                    <el-form-item label="商品类别" props="category">
                        <el-select v-model="params.category" placeholder="请选择商品类别" @change="search">
                            <el-option v-for="item in product_category2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付状态" props="order_status">
                        <el-select v-model="params.order_status" placeholder="请选择支付状态" @change="search">
                            <el-option v-for="item in order_status2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="充值来源" props="order_source">
                        <el-select v-model="params.order_source" placeholder="请选择充值来源" @change="search">
                            <el-option v-for="item in order_source2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始时间"
                            end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="search" :clearable="true">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="table2" :data="list" v-loading="loading" @sort-change="onSortChange" style="width: 100%"
                        header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_source_text" label="订单来源" width="120">
                        </el-table-column>
                        <el-table-column prop="order_num" label="订单编号">
                        </el-table-column>
                        <el-table-column prop="product_category_text" label="商品类别" width="120">
                        </el-table-column>
                        <el-table-column prop="order_name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="price" label="钻石(钻)" width="120">
                        </el-table-column>
                        <el-table-column prop="order_status_text" label="订单状态" width="120">
                        </el-table-column>
                        <el-table-column prop="create_time" label="下单时间" width="110" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="pay_time" label="交易时间" width="110" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="trade_no" label="交易流水号" width="120">
                        </el-table-column>
                        <el-table-column prop="remark" label="备注">
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="80">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="orderDetailsBtn(2, scope.row.id)">
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                            @currentChange='currentChangeBtn'></paging-page>
                    </div>
                </div>
            </div>
            <div class="content3 p20" v-if="activeName == 'oTab3' && $has('515')">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
                    <el-form-item label="商品类别" props="category">
                        <el-select v-model="params.category" placeholder="请选择商品类别" @change="search">
                            <el-option v-for="item in product_category2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付状态" props="order_status">
                        <el-select v-model="params.order_status" placeholder="请选择支付状态" @change="search">
                            <el-option v-for="item in order_status2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="充值来源" props="order_source">
                        <el-select v-model="params.order_source" placeholder="请选择充值来源" @change="search">
                            <el-option v-for="item in order_source2" :key="item.value" :label="item.label"
                                :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始时间"
                            end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="search" :clearable="true">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="table3" :data="list" v-loading="loading" @sort-change="onSortChange" style="width: 100%"
                        header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_source_text" label="订单来源" width="120">
                        </el-table-column>
                        <el-table-column prop="order_num" label="订单编号" width="160">
                        </el-table-column>
                        <el-table-column prop="product_category" label="商品类别" width="120">
                        </el-table-column>
                        <el-table-column prop="product_name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="order_status_text" label="订单状态" width="120">
                        </el-table-column>
                        <el-table-column prop="create_time" label="下单时间" width="110" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="pay_time" label="付款时间" width="110" sortable="custom">
                        </el-table-column>
                        <el-table-column prop="trade_no" label="交易流水号">
                        </el-table-column>
                        <!-- <el-table-column label="操作" fixed="right" width="80">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="orderDetailsBtn(2, scope.row.id)">
                                    查看
                                </el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                            @currentChange='currentChangeBtn'></paging-page>
                    </div>
                </div>
            </div>
        </div>
        <!-- 订单信息 -->
        <order-info ref="orderInfo"></order-info>
        <invest-money ref="InvestMoney" @renovate='getParams' />
    </div>
</template>
<script>
import { mapState } from "vuex"
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import OrderInfo from '../components/order_info/order_info.vue'//订单信息
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import InvestMoney from '@/components/invest_money/invest_money.vue'//充值
export default {
    components: { EmptyData, OrderInfo, PagingPage, InvestMoney },
    props: {},
    data() {
        return {
            activeName: '',
            order_source1: [],
            order_status1: [],
            order_source2: [],
            order_status2: [],
            product_category2: [],
            params: {
                category: '',
                order_status: '',
                order_source: '',
                start_date: '',
                end_date: '',
                order_field: '', // 排序字段
                order_redirect: '', // 排序方向：正序：asc，倒序：desc
            },
            date: [],
            page: 1,
            limit: 10,
            total: 0,
            loading: false,
            list: [],
            balance: {}, // 余额

        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo.user_info, // 用户信息
        }),
    },
    watch: {},
    created() {
        if (this.$has('514')) {
            this.activeName = 'oTab1'
        } else if (this.$has('515')) {
            this.activeName = 'oTab2'
        }
        this.getParams()
        this.getList()
    },
    mounted() { },
    methods: {
        getParams() {
            // 充值列表查询参数
            this.$personApi.orderRechargeParams().then(res => {
                if (res.code == 1000) {
                    this.order_source1 = res.result.order_source
                    this.order_status1 = res.result.status
                    this.balance = res.result?.balance
                } else {

                }
            })
            if (this.$has('516')) {
                // 消耗列表查询参数
                this.$personApi.orderConsumeParams().then(res => {
                    if (res.code == 1000) {
                        this.order_source2 = res.result.order_source
                        this.order_status2 = res.result.order_status
                        this.product_category2 = res.result.product_category
                    } else {

                    }
                })
            }
        },
        // tab切换
        handleClick(tab) {
            this.activeName = tab.name
            this.resetFrom()
        },
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.params.start_date = this.date.length ? this.date[0] : ''
            this.params.end_date = this.date.length ? this.date[1] : ''
            this.getList()
        },
        resetFrom() {
            this.date = []
            this.params = {
                category: '',
                order_status: '',
                order_source: '',
                start_date: '',
                end_date: '',
                order_field: '', // 排序字段
                order_redirect: '', // 排序方向：正序：asc，倒序：desc
            },
            this.search()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
        // 列表排序
        onSortChange(column) {
            if (column.order == 'ascending') this.params.order_redirect = 'asc'
            if (column.order == 'descending') this.params.order_redirect = 'desc'
            if (!column.order) this.params.order_redirect = ''
            this.params.order_field = column.prop
            this.search()
        },
        getList() {
            var params = {
                page: this.page,
                limit: this.limit,

                ...this.params
            }
            // 消耗和兑换列表：pay_type必传  3=钻石消耗 4=兑换码
            if (this.activeName == 'oTab2') params.pay_type = 3
            if (this.activeName == 'oTab3') params.pay_type = 4

            // 充值列表
            if (this.activeName == 'oTab1' && this.$has('514')) {
                this.loading = true
                this.$personApi.orderRechargeList(params).then(res => {
                    this.loading = false

                    if (res.code == 1000) {
                        this.list = res.result.data
                        this.total = res.result.total
                    } else {

                    }
                })

            } else { // 消耗列表、兑换码列表
                if (!this.$has('515')) return
                this.loading = true
                this.$personApi.orderConsumeList(params).then(res => {
                    this.loading = false

                    if (res.code == 1000) {
                        this.list = res.result.data
                        this.total = res.result.total
                    } else {

                    }
                })

            }

        },
        // 订单详情
        orderDetailsBtn(type, id) {
            this.$refs.orderInfo.openDialogBtn(type, id)
        },
        // 充值
        investBtn(type) {
            this.$refs.InvestMoney.openDialogBtn(type)
        }
    },
}
</script>
<style lang='scss' scoped>
@import '../index.scss';

.order {
  min-width: 1600px;

    .con_top {
        display: flex;
        margin-left: -30px;

        .item {
            flex: 1;
            height: 130px;
            padding: 30px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 30px;

            .item_l {
                .label {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 22px;
                }

                .value {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 42px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 30px;
                        margin-top: 5px;
                        margin-left: 5px;
                    }

                    div {
                        display: flex;
                        align-items: center;
                    }

                    span {
                        display: block;
                        font-size: 14px;
                        padding-top: 5px;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }

            .item_r {
                .btn {
                    width: 90px;
                    height: 38px;
                    background: #FFFFFF;
                    border-radius: 19px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    line-height: 38px;
                    text-align: center;
                    cursor: pointer;
                }

                .btn1 {
                    color: #2E6DFE;
                }

                .btn2 {
                    color: #0198AE;
                }

                .btn3 {
                    color: #EC497C;
                }

                .btn4 {
                    color: #884EF7;
                }
            }
        }

        .i_c_1 {
            background: url('~@/assets/images/person/order/order1.png') no-repeat;
            background-size: cover;
        }

        .i_c_2 {
            background: url('~@/assets/images/person/order/order2.png') no-repeat;
            background-size: cover;
        }

        .i_c_3 {
            background: url('~@/assets/images/person/order/order3.png') no-repeat;
            background-size: cover;
        }

        .i_c_4 {
            background: url('~@/assets/images/person/order/order4.png') no-repeat;
            background-size: cover;
        }
    }

    .con_bottom {
        margin-top: 20px;
    }
    .details_text{
      color: #666;
      span{
        color: #EC497C;
      }
    }
}</style>
